<template>
  <div>
    <NavigationBar titel="Organigramm"></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <v-data-iterator
                :items="membersPrepared"
                :search="membersSearch"
                :sort-by="sortBy.toLowerCase()"
                disable-sort
                disable-pagination
                hide-default-footer
                pagin
                no-results-text="Keine Mitglieder zum Suchbegriff gefunden."
              >
                <template v-slot:header>
                  <v-row>
                    <v-col>
                      <v-card class="mb-2">
                        <v-card-title>
                          <v-text-field
                            v-model="membersSearch"
                            outlined
                            label="Nach Person/Benutzergruppen suchen"
                            dense
                            prepend-inner-icon="mdi-magnify"
                            clearable
                            hide-details
                          ></v-text-field>
                        </v-card-title>
                      </v-card>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:default="props">
                  <v-row class="match-height">
                    <template v-for="member in props.items">
                      <v-col v-if="member.header" :key="member.id" cols="12"
                        ><div class="text-h5 font-weight-medium">
                          {{ member.header }}
                        </div></v-col
                      >
                      <v-col
                        v-else
                        :key="member.user.uid"
                        cols="12"
                        lg="4"
                        md="6"
                        sm="6"
                      >
                        <personnel-record-detail-card
                          :member="member"
                        ></personnel-record-detail-card>
                      </v-col>
                    </template>
                  </v-row>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PersonnelRecordDetailCard from "@/components/member-management/personnel-records/PersonnelRecordDetailCard.vue";
import { ADMIN } from "@/store/modules.js";
import { GET_USER_LIST } from "@/store/action-types.js";

export default {
  name: "my-organization-organization-chart",
  components: {
    NavigationBar,
    PersonnelRecordDetailCard,
  },
  data() {
    return {
      membersSearch: "",
      sortBy: "searchableDisplayName",
      sortDesc: false,
    };
  },
  computed: {
    members() {
      const input = this.$store.state.admin.membershipsPublic;
      const result = input.map((content) => ({
        ...content,
        searchableDisplayName: content.user.displayName,
        // skills: {
        //   relevant: {
        //     data: [
        //       {
        //         shortTitle: "TF",
        //         group: { icon: "fire-truck", color: "#F44336FF" },
        //         id: "1",
        //       },
        //       {
        //         shortTitle: "RetSan",
        //         group: { icon: "medical-bag", color: "#00BCD4FF" },
        //         id: "2",
        //       },
        //       {
        //         shortTitle: "Klasse C",
        //         group: { icon: "car", color: "#795548FF" },
        //         id: "3",
        //       },
        //     ],
        //   },
        // },
      }));
      return result;
    },
    membersPrepared() {
      // Input data
      const input = this.members;

      // Helper function to group items by "group.id"
      const groupItems = (arr) => {
        return arr.reduce((grouped, item) => {
          const groupId = item.group.id;
          if (!grouped[groupId]) {
            grouped[groupId] = [];
          }
          grouped[groupId].push(item);
          return grouped;
        }, {});
      };

      // Step 1: Group items by "group.id"
      const groupedItems = groupItems(input);

      // Helper function to sort headers by "sortKey"
      const sortHeaders = (a, b) => a.sortKey - b.sortKey;

      // Helper function to sort group members by "user.displayName"
      const sortGroupMembers = (a, b) =>
        a.user.displayName.localeCompare(b.user.displayName);

      // Step 2: Generate headers and sort them by "sortKey"
      const headers = Object.entries(groupedItems).map(
        ([groupId, groupMembers]) => ({
          header: groupMembers[0].group.title,
          id: groupId,
          sortKey: groupMembers[0].group.sortKey,
        })
      );
      headers.sort(sortHeaders);

      // Step 3: Sort group members alphabetically by "user.displayName" within each group
      const result = headers.flatMap((header) => {
        const groupMembers = groupedItems[header.id];
        groupMembers.sort(sortGroupMembers);
        return [header, ...groupMembers];
      });

      return result;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ADMIN}${GET_USER_LIST}`, {
        organizationId: this.$route.params.organizationId,
        queryIsFiltered: true,
        query: {
          type: "active",
        },
      });
    },
  },
};
</script>
